import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const config = {
	apiKey: 'AIzaSyDIVx5zV6SPXr0BahFQYj9lchcnrQhIRz0',
	authDomain: 'chama-challenge.firebaseapp.com',
	databaseURL: 'https://chama-challenge.firebaseio.com',
	projectId: 'chama-challenge',
	storageBucket: 'chama-challenge.appspot.com',
	messagingSenderId: '539891775343',
}

firebase.initializeApp(config)

export default firebase
export const todosRef = firebase.database().ref('todos')
export const provider = new firebase.auth.GoogleAuthProvider()


export const toArray = snapshot => {
	const array = []
	try{
		snapshot.forEach(child => {
			const value = child && child.val && child.val()
			if(typeof value !== 'object') return array.push({key: child.key, value})
			return array.push({...value, key: child.key})
		})
	}catch(e){
		// if argument isn't a snapshot, return an empty array
	}
	return array
}
